import {NgModule} from "@angular/core";
import {InhaberNavigationComponent} from "./inhaber-navigation.component";
import {BadgeModule} from "../badge/badge.module";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';

@NgModule({
  declarations: [
    InhaberNavigationComponent,
  ],
  imports: [
    BadgeModule,
    CommonModule,
    RouterModule,
    MatListModule,
    MatTooltipModule
  ],
  exports: [
    InhaberNavigationComponent,
  ]
})
export class InhaberNavigationModule {

}
