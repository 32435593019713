import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "./auth/auth.guard";
import {FetchUmsaetze} from './resolvers/fetch-umsaetze';
import {FetchUmsaetzeWidgets} from './resolvers/fetch-umsaetze-widgets';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/overview',
    pathMatch: 'full',
    resolve: [FetchUmsaetze],
  },
  {
    path: 'overview',
    loadChildren: () => import('./modules/overview/overview.module').then(m => m.OverviewModule),
    canActivate: [AuthGuard],
    resolve: [FetchUmsaetze],
    data: {
      redirectAllowed: true,
      useInhaberService: true,
    },
  },
  {
    path: 'to-assign',
    loadChildren: () => import('./modules/to-assign/to-assign.module').then(m => m.ToAssignModule),
    canActivate: [AuthGuard],
    resolve: [FetchUmsaetze],
    data: {
      redirectAllowed: true,
      useInhaberService: true,
    },
  },
  {
    path: 'to-clarify',
    loadChildren: () => import('./modules/to-clarify/to-clarify.module').then(m => m.ToClarifyModule),
    canActivate: [AuthGuard],
    resolve: [FetchUmsaetze],
    data: {
      redirectAllowed: true,
      useInhaberService: true,
    },
  },
  {
    path: 'all-assigned',
    loadChildren: () => import('./modules/all-assigned/all-assigned.module').then(m => m.AllAssignedModule),
    canActivate: [AuthGuard],
    resolve: [FetchUmsaetze],
    data: {
      redirectAllowed: true,
      useInhaberService: true,
    },
  },
  {
    path: 'widget/utility-widget',
    loadChildren: () => import('./modules/widget/utility-widget/utility-widget.module').then(m => m.UtilityWidgetModule),
    canActivate: [AuthGuard],
    resolve: [FetchUmsaetzeWidgets],
    data: {
      redirectAllowed: true,
      useInhaberService: true,
    },
  },
  {
    path: 'widget/konten',
    loadChildren: () => import('./modules/widget/konten/konten.module').then(m => m.KontenModule),
    canActivate: [AuthGuard],
    data: {
      redirectAllowed: true,
      useInhaberService: true,
    },
  },
  {
    path: 'impressum',
    loadChildren: () => import('./modules/legal/impressum/impressum.module').then(m => m.ImpressumModule),
  },
  {
    path: 'error',
    loadChildren: () => import('./modules/error-pages/error-pages.module').then(m => m.ErrorPagesModule),
    canActivate: [AuthGuard],
    resolve: [FetchUmsaetze],
  },
  {
    path: '**',
    redirectTo: '/overview'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      preloadingStrategy: PreloadAllModules,
    }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
