import {NgModule} from "@angular/core";
import {LegalPanelComponent} from "./legal-panel.component";
import {RouterModule} from "@angular/router";

@NgModule({
  declarations: [
    LegalPanelComponent,
  ],
  imports: [
    RouterModule,
  ],
  exports: [
    LegalPanelComponent,
  ]
})
export class LegalPanelModule {

}
